@import "colors";

@mixin bodyFonts {
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;

}

@mixin standartBody {
  @include bodyFonts;
  font-size: 20px;
  line-height: 30px;
}

@mixin mobileBody {
  @include bodyFonts;
  font-size: 18px;
  line-height: 28px;
}

@mixin mobileBodyWhite {
  @include bodyFonts;
  font-size: 18px;
  line-height: 28px;
}

@mixin displayFonts {
  color: $c-primary;
}

@mixin display1 {
  @include displayFonts;
  font-size: 61.04px;
  line-height: 76px;
}

@mixin display2 {
  @include displayFonts;
  font-size: 48.83px;
  line-height: 60px;
}

@mixin display3 {
  @include displayFonts;
  font-size: 40px;
  line-height: 50px;
}

@mixin display4 {
  @include displayFonts;
  font-size: 32px;
  line-height: 40px;
}

@mixin display3-link {
  font-size: 39.06px;
  line-height: 50.8px;
}