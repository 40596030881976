@import "colors";
@import "typography";
@import "spacing";
@import "buttons";
@import "accordion";
@import "cookies_style";
/* Css Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol,
li {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  @include mobileBody;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: $c-pure;

  @media screen and (min-width:800px) {
    font-weight: 100;
    
  }
}

ul[class],
ol[class],
li {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

a:link, a:active, a:visited {
  text-decoration: none;
  color: $c-third;
}




/*-TYPOGRAPHY*/

.title1 {
  @include display3;
  color: $c-pure;
  
  @media screen and (min-width: 800px) {
    @include display1;
    color: $c-pure;
  }

}

.title2 {
  @include display4;
  @media screen and (min-width: 800px) {
    font-size: 48px;
    line-height: 58px;
  } 
}

.title3 {
  color: $c-neutral;
}

.text-notwhite {
  color: #828282;
}

/* SECTIONS */

section {
  padding-left: $space-s;
  padding-right: $space-s;
}

.intro-section {
  background-color: $c-primary;
  position: relative;
  padding-top: $space-m;

  .red-paint-mobile {
    position: absolute;
    top: 0;
    right: 38px;
    width: 135px;
    height: 420px;
    background-color: $c-secondary;
    z-index: 0;

    @media screen and (min-width:800px){
      display: none;
    }

    }
    
    .img-container-mobile {
    height: 230px;
    width: 260px;
    position: absolute;
    z-index: 0;
    top: 340px;
    right: 0;
    overflow: hidden;

      div {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translate(55px, 10px);
        background-size: cover;
        background-image: url('img/paint.png');
      }

    @media screen and (min-width:800px){
      display: none;
    }

    }

  .red-paint {
    display: none;
    position: absolute;
    top: 0;
    left: 14%;
    width: 200px;
    height: 300px;
    background-color: $c-secondary;
    z-index: 0;

    @media screen and (min-width:800px){
      display: block;
    }
  }

  .img-container {
    display: none;
    height: 300px;
    width: 400px;
    position: absolute;
    z-index: 1;
    left: 14%;

    @media screen and (min-width:800px){
      display: block;

    div {
      position: absolute;
      width: 100%;
      height: 120%;
      transform: translate(-50px, 10px);
      background-size: cover;
      background-image: url('img/paint.png');
    }
    
    }


  }

  header {
    position: relative;
    z-index: 2;

    nav {
      display: flex;
    
      .anchorc:hover {
        color: $c-secondary;
      }

    img {
      padding-bottom: $space-xl;
      flex-grow: 3;
      max-width: 221px;
      padding-top: 36px;

      @media screen and (min-width: 800px) {
        padding-top: $space-l;
        padding-left: $space-xxl;
        max-width: 330px;
      }

    }

    @media screen and (min-width: 800px) {
      display: flex;
    }

    ul {
      display: none;

      li {

        svg {
          height: 24px;
          width: 24px;
          #Square {
            fill: $c-third;
  
            &:hover {
              fill:$c-secondary;
            }
          }
        }

        @media screen and (min-width: 800px) {
          padding-left: $space-m;
        }

      }

      @media screen and (min-width: 800px) {
      display: flex;
      width: 100%;
      padding-top: 32px;
      padding-right: 48px;
      justify-content: flex-end;
    }
    }

  }

  }
  

  .intro-text {
    position: relative;
    z-index: 2;
    padding-bottom: 240px;

    h1 {
      padding-bottom: $space-s;

      @media screen and (min-width: 762px) {
        width: 632px;
        padding-top: $space-xl;
        font-size: 84px;
        line-height: 92px;
      }

      @media screen and (min-width: 1250px) {
        width: 682px;
        font-size: 90px;
        line-height: 105px;
      }
    }

    p {
      @media screen and (min-width: 762px) {
        width: 420px;
      }
    }

    @media screen and (min-width: 800px) {
      padding-left: 20%;
      padding-bottom: 610px;
    }

  }

  .zone {
    margin-bottom: $space-xl;
    padding-top: 228px;

    img {
      padding-bottom: $space-m;
    }

    .trinta-desc {
      padding-bottom: $space-xl;

      h2 {
        padding-bottom: $space-s;
      }

      @media screen and (min-width: 762px) {
        max-width: 500px;
        padding-bottom: $space-xxl;
      }
    }

    @media screen and (min-width: 762px) {
      padding-left: 45%;
      padding-top: 0px;
    }

  }

}

.gallery-section {
  margin: 0 auto;

  .gallery-intro {
    position: relative;
  }
  
  h2 {
    padding-bottom: $space-s;

    @media screen and (min-width: 800px) {
      padding-left: 10%;
    }

  }

  .accord-h2 {
    @media screen and (min-width: 800px) {
      padding-left: 0;
    }

  }

  .text-notwhite {
    padding-bottom: $space-ml;

    @media screen and (min-width: 800px) {
      max-width: 512px;
      padding-left: 10%;
    }
  }

  .wrap-figures {
    display: flex;
    flex-direction: column;

    figure:first-child {
      margin-bottom: $space-s;
    }

    figure:last-child {
      margin-bottom: $space-m;
    }

    @media screen and (min-width:800px){
      display: none;
    }

  }

  figure {
    position: relative;
    width: 100%;
    //overlay
    &::after {
      content: "";
      background: $c-secondary;
      width: 100%;
      height: 242px;
      position: absolute;
      left: 0;
      opacity: 0.8;
      bottom: 0;
    }
    img {
      height: 460px;
      min-width: 100%;
      object-fit: cover;
    }
    figcaption {
      width:100%;
      height: 242px;
      position: absolute;
      bottom: 0;
      z-index: 3;
      color: $c-pure;
      padding-left: $space-s;
      padding-right: $space-s;

      h4{
        font-size: 24px;
        line-height: 60px;
      }
      
    }
  }

  padding-bottom: 46px;
  @media screen and (min-width: 800px){
    padding-bottom: $space-xl;
  }

}

.serv-section {
  position: relative;
  height: 1450px;
  padding: 0;

  

  .serv-section-intro {
    padding-left: $space-s;
    position: relative;
    opacity: 0;

    h2 {
      padding-bottom: $space-s;
    }

    .text-notwhite {
      max-width: 500px;
      padding-right: 16px;
      padding-bottom: $space-ml;
    }

    @media screen and (min-width: 800px){
      padding-left: 10%;
    }

  }


  .car-wrap {

    display: none;

    @media screen and (min-width: 900px) {
    position: relative;
    z-index: 2;
    display: block;
    height: 800px;
    width: 600px;
    }

    .car {

      @media screen and (min-width:800px) {
        position: absolute;
        height: 140%;
        width: 101%;
        background-image: url(img/car.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      @media screen and (min-width:1050px){
        height: 150%;
        width: 112%;
      }

      @media screen and (min-width:1450px){
        height: 100%;
        width: 95%;
      }
  
    }
    
  }

  .list-bg-grey {

    ul {
      display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width:900px) {
      display: block;
      
    }


    }
    

    .um {
      background-image: url('img/itemsgrid/11.svg');
      background-size: cover;
      width: 275px;
      height: 157px;
    }

    .dois {
      background-image: url('img/itemsgrid/22.svg');
      background-size: cover;
      width: 275px;
      height: 157px;
    }

    .tres {
      background-image: url('img/itemsgrid/33.svg');
      background-size: cover;
      width: 275px;
      height: 157px;
    }

  }

  .list-bg-red {

    ul {
      display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width:900px) {
      display: block;
      
    }

    }

    .quatro {
      background-image: url('img/itemsgrid/44.svg');
      background-size: cover;
      width: 275px;
      height: 157px;
    }

    .cinco {
      background-image: url('img/itemsgrid/55.svg');
      background-size: cover;
      width: 275px;
      height: 157px;
    }

    .seis {
      background-image: url('img/itemsgrid/66.svg');
      background-size: cover;
      width: 275px;
      height: 157px;
    }

  }

  .servicos {
    display: inline-block;
    margin-top: $space-m;
    margin-bottom: $space-m;
  }

  .bg-red {
    display: none;

    @media screen and (min-width: 900px){
      display: block;
      background-color: #E24139;
      height: 672px;
      width: 101%;
    }

    @media screen and (min-width: 1170px){
      height: 460px;
    }

    @media screen and (min-width: 1450px){
      height: 248px;
    }

    
  }

  .list-bg-grey {
    background-color: #F6F6F6;
    padding-left: $space-s;
    margin: 0;

    ul{
      margin: 0;
      @media screen and (min-width:1170px){
        padding-top: 64px;
      }
    }
  }

  .list-bg-red {
    background-color: #E24139;
    position: absolute;
    padding-left: $space-s;
    width: 100%;
    margin: 0;
  }

  @media screen and (min-width: 900px){
    display: grid;

    .serv-section-intro {
      grid-area: intro;
    }

    .car-wrap {
      grid-area: car;
    }

    .bg-red {
      grid-area: bg-red;
    }

    .list-bg-grey {
      grid-area: listg;
    }

    .list-bg-red {
      grid-area: listr;
    }
    
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 184px 700px 700px;
    grid-template-areas:
      "intro intro"
      "car listg"
      "bg-red listr";
  }

  @media screen and (min-width: 1450px){
    display: grid;

    .serv-section-intro {
      grid-area: intro;
    }

    .car-wrap {
      grid-area: car;
    }

    .bg-red {
      grid-area: bg-red;
      height: 400px;
    }

    .list-bg-grey {
      grid-area: listg;
      display: flex;
      justify-content: space-around;
      ul{
        display: flex;
        flex-direction: row;
      }
    }

    .list-bg-red {
      grid-area: listr;
      display: flex;
      justify-content: space-around;
      ul{
        margin: 0;
        display: flex;
        flex-direction: row;
        height: 400px;
      }
    }
    
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 200px 400px 400px;
    grid-template-areas:
      "intro intro"
      "car listg"
      "bg-red listr";
  }

  @media screen and (min-width:900px){
    height: auto;
    
  }

}


.photo-section {
  padding: $space-s;
  padding-top: 144px;

  .wrap {
    position: relative;
    @media screen and (min-width:1050px){
      position: absolute;
    }
  }

  div {
    margin: -16px;
    width: 100vw;
    height: 300px;

    @media screen and (min-width:700px){
      width: 100%;
      height: 600px;
    }

    @media screen and (min-width:1050px){
      position: absolute;
      height: 100%;
      width: 90%;
    }

  .imga {
    background-image: url('img/asset_gallery_02.png');
    background-position: center;
    background-size: cover;


    i {

      width: 48px;
    height: 40px;
    position: absolute;
    z-index: 3;
    top: 90%;
    left: 50%;
    animation-name: fadedois;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-direction: alternate;

    @media screen and(min-width:763px){
      top: 94%;
    }

      .mcum {
        display: inline-block;
        height: 18px;
        width: 18px;
        position: absolute;
        border-radius: 50%;
        left: 0;
        z-index: 8;
        opacity: 1;

        border: 2px solid white;
        
      }
      .mcdois {
        display: inline-block;
        height: 18px;
        width: 18px;
        position: absolute;
        right: 0;
        border-radius: 50%;
        z-index: 8;
        opacity: 1;
        background: white;
      }
    }
  }

  .imgadois {
    background-image: url('img/asset_gallery_01.png');
    background-size: cover;
    background-position: 50% 100%;
    animation-name: fade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-direction: alternate;


    i {
      width: 48px;
    height: 40px;
    position: absolute;
    z-index: 5;
    top: 90%;
    left: 50%;
    opacity: 1;

    @media screen and(min-width:763px){
      top: 94%;
    }
    

      .mcum {
        display: inline-block;
        height: 18px;
        width: 18px;
        position: absolute;
        border-radius: 50%;
        left: 0;
        z-index: 5;
        background: white;
      }
      .mcdois {
        display: inline-block;
        height: 18px;
        width: 18px;
        position: absolute;
        right: 0;
        border-radius: 50%;
        z-index: 5;
        border: 2px solid white;
      }
    }
  }

  .imgs {
    position: absolute;
    top:0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;

  }

  @-webkit-keyframes fade {
    0% {opacity: 1;}
    45% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 0;}
}
@keyframes fade {
    0% {opacity: 1;}
    45% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 0;}
}
@keyframes fadedois {
  0% {opacity: 0;}
  45% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 1;}
}
  }

  @media screen and (min-width:800px){


    padding-top: 84px;
    padding-bottom: $space-xl;
    
  }

  @media screen and (min-width:1050px){
    position: relative;
    height: 700px;
    
  }

  @media screen and (min-width:1450px){
    padding-top: 300px;
    position: relative;
    height: 800px;
    
  }

}

.team-section {

  padding-top: 24px;

  .intro-wrap {
    position: relative;

    @media screen and (min-width:800px){
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 22%;
      
    }
    
  }

  h2 {
    padding-top: $space-m;
    padding-bottom: $space-s;

    @media screen and (min-width:800px){
      width: 520px;
      
    }

    @media screen and (min-width:1050px){
      
      padding-top:100px;
        
      }

    @media screen and (min-width:1450px){
      
    padding-top:358px;
      
    }

  }

  .text-notwhite {
    padding-bottom: $space-ml;

    @media screen and (min-width:800px){
      width: 520px;
      padding-bottom: $space-xxl;
      
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    li {
      position: relative;
      text-align: center;
      padding-bottom: $space-l;
      div {
        width: 289px;
        height: 427px;
        margin: auto;
       
        @media screen and (min-width: 800px) {
          width: 329px;
          height: 467px;
          margin: auto;
        }
      }
      .photos-workers {

        @media screen and (min-width: 800px) {
          max-width: 270px;
        }

        @media screen and (min-width: 1000px) {
          max-width: 100%;
        }
        

      }

      .noe {
        background-image: url("img/noe.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .filipe {
        background-image: url("img/filipe.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .ricardo {
        background-image: url("img/ricardo.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      h3 {
        padding-top: $space-m;
        color: $c-primary;
        padding-bottom: $space-xs;
        font-size: 28px;
      }
      p {
        color: $c-secondary;
        padding-bottom: $space-s;
      }
      a {
        width: 24px;
        height: 24px;
        display: block;
        margin: 0 auto;

        svg {
          height: 24px;
          width: 24px;
          fill: #0077B7;
  
            &:hover {
              fill:$c-secondary !important;
            }
          
        }

      }

      
    }
  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 165px;
  }
  }
}

.brands {
  overflow: hidden;
  h2 {
    text-align: center;
    @media screen and (min-width: 800px) {
      padding-bottom: $space-xl;
      font-size: 46px;
    }
  }
  div {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1200px) {
      .bg {
      height: 320px;
      width: 90%;
      border-radius: 8px 0 0 8px;
      right: 0;
      background: #E5E5E5;
      position: absolute;
      z-index: -2;
    }

    }
    
    .logobtnwrap {
      display: flex;

      &:last-of-type {
        padding-bottom: 84px;
      }

      @media screen and (min-width: 800px) {
        flex-direction: column;

        &:last-of-type {
          padding-bottom: inherit;
        }

      }
     
    }

    img {
      width: 240px;
      margin: auto;
    }

    .btnone {
      font-size: 16px;
      background-color: $c-primary;
      padding: 6px 12px;
      border-radius: 8px;
      color: $c-pure;
      width: 140px;
      margin: auto;
      text-align: center;

      &:hover {
        background-color: $c-third;
      }
    }

  @media screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: $space-xxl;
  }

  }
}

footer {
  background-color: $c-primary;
  padding-left: $space-s;
  padding-right: $space-s;

  .f-intro-wrap {
    position: relative;
    z-index: 2;

    @media screen and (min-width: 800px) {
      padding-left: $space-xl;
    }
  }

  h3 {
    @include display2;
    color: $c-pure;
    padding-bottom: $space-s;
    padding-top: $space-l;
  }

  h4 {
    @include display4;
    color: $c-pure;
    padding-bottom: $space-s;
  }

  p {
    color: $c-third;
    padding-bottom: $space-m;
  }
  
  .f-title {
    display: block;

    @media screen and (min-width: 800px) {
      display: none;
    }
  }

  .f-title-d {
    display: none;

    @media screen and (min-width: 800px){
      display: block;
      max-width: 435px;
    }
  }

  .f-intro {
    max-width: 420px;
  }

  .the-map {
    position: relative;
    z-index: 2;

    iframe {
    width: 100%;
    height: 340px;
    padding-bottom: $space-m;

      @media screen and (min-width: 800px) {
        width: 100%;
        height: 500px;
        position: relative;
        right: -16px;
      }
    }
  }

  .directions {
    position: relative;
    z-index: 2;

    .semib {
      font-weight: 400;
    }

    @media screen and (min-width:800px) {
      padding-left: $space-xl;
      max-width: 300px;
    }

    @media screen and (min-width:1200px) {
      padding-left: 150px;
      max-width: 420px;
    }

    

  }

  .fut {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    font-size: 15px;
    
    @media screen and (min-width:800px){
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      padding-top: $space-m;
    }

    @media screen and (min-width:1200px){
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding-top: $space-m;
      padding-left: 150px;
      padding-right: 150px;
    }

    li {
      padding-bottom: $space-m;
      color: $c-third;

      .fut-linkedin {
        padding-left: 24px;
        @media screen and (min-width:800px){
          padding-left: $space-m;
        }
      }

      svg {
        height: 24px;
        width: 24px;
        #Square {
          fill: $c-third;

          &:hover {
            fill:$c-secondary;
          }
        }
      }
    }
  }

  @media screen and (min-width: 800px) {

    display: grid;

    div {
      grid-area: intro;
    }

    .directions {
      grid-area: three;
    }

    .the-map {
      grid-area: map;
    }

    .fut {
      grid-area: tag;
    }
    
    grid-template-columns: 1fr 2fr;
    grid-column-gap: $space-s;
    grid-template-rows: auto 700px auto;
    grid-template-areas:
      ". intro"
      "three map"
      "tag tag";
  }

  .f-red-flag {
    
    display: none;
    @media screen and (min-width: 800px) {
      display: block;
      border-radius: 8px 8px 0 0;
      background-color: $c-secondary;
    height: 951px;
    width: 200px;
    left: 30%;
    transform: translate(10%, 6.5%);
    z-index: 1;
    position: absolute;
    }   
    
  }
}

.ident {
  padding-left: 16px;
}

@-moz-document url-prefix() {
  .cc {
    white-space: pre;
  }
  
  .ci {
    white-space: pre;
  }
}

///-- Politica privacidade
.return-politica {
  background-color: $c-primary;
  display: flex;
  padding: 24px;
  div {
    max-width: 184px;
  }
}

.politica {
  margin: 0 auto;
  max-width: 800px;
  padding-top: 56px;
  padding-bottom: 56px;
  h1, h2, p {
    color: $c-primary;
  }

  h1, h2 {
    padding-bottom: 8px;
  }
  p {
    padding-bottom: 24px;
  }
}